/** @format */

import UserState from './UserState';

// ADD ENTRY OF YOUR STATE HERE
class RootState {

    UserState: UserState;


    constructor() {
        this.UserState = new UserState();
    }
}

export default RootState;
