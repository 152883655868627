/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/naming-convention */

import { makeAutoObservable } from 'mobx';
// import setAuthToken from '../../utils/setAuthToken';


export default class UserState {
    templateList: Array<any> = [];

    userData = {
        email: '',
        firstName: '',
        lastName: '',
        contactNum: '',
        status: '',
        role: '',
        userStatus: '',
        isAuthenticated: false,
    };

    // isUserLoggedIn = false;

    // userType: string;

    authToken = '';
    loginView = true;

    constructor() {
        makeAutoObservable(this);
    }

    async setCurrentUserState(payload: { email: any, firstName: any, lastName: any, contactNum: any, status: any, role: any, userStatus: any, isAuthenticated: any; }) {
        const {
            email,
            firstName,
            lastName,
            contactNum,
            status,
            role,
            userStatus,
            isAuthenticated,
        } = payload;

        // SETTING CURRENT USER GLOBAL STATE
        this.userData.isAuthenticated = isAuthenticated;
        this.userData.email = email;
        this.userData.firstName = firstName;
        this.userData.lastName = lastName;
        this.userData.contactNum = contactNum;
        this.userData.status = status;
        this.userData.role = role;
        this.userData.userStatus = userStatus;

    }

    async setAuthToken(token: string) {
        this.authToken = token;
    }

    async setLoginView(view: boolean) {
        this.loginView = view;
    }

    onLogout() {
        // Remove token from local storage
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        // Remove auth header for future requests

        this.userData.isAuthenticated = false;
        this.userData.email = '';
        this.userData.firstName = '';
        this.userData.lastName = '';
        this.userData.contactNum = '';
        this.userData.status = '';
        this.userData.role = '';
        this.userData.userStatus = '';
        this.authToken = '';
    }

}
