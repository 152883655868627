/** @format */

import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import './App.css';
import useStore from './mobx/UseStore';

const Login = React.lazy(() => import('./views/Login'));
const ViewUser = React.lazy(() => import('./views/User'));
const PrivateRoute = React.lazy(() => import('./views/PrivateRoute'));


// eslint-disable-next-line react/prop-types

let App = (props: any) => {
    const { UserState } = useStore();
    useEffect(() => {
        const handleTabClose = (event: any) => {
            event.preventDefault();
            UserState.onLogout();
            // console.log('beforeunload event triggered');

            return (event.returnValue = 'Are you sure you want to exit?');
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);
    return (
        <div className="h-screen App">
            <Suspense fallback={<div className='loading' />}>
                <Router>
                    <Routes>
                        {/* OLD ROUTES */}

                        <Route path="/login" element={<Login />} />

                        {/* PRIVATE ROUTE FOR LOGGED USER */}
                        <Route path="user/*" element={<PrivateRoute Component={ViewUser} />} />
                        <Route path="*" element={<PrivateRoute Component={ViewUser} />} />
                    </Routes>
                </Router>
            </Suspense>
        </div>
    );
};


export default observer(App);
